import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase/config';
import axios from 'axios';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiresTelemetry?: boolean;
  isAuthenticated: boolean; 
}

interface TelemetryStatus {
  status: 'enabled' | 'disabled' | 'error';
  lastCheck?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('Protected route error:', error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
            <h2 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h2>
            <p className="text-gray-600 mb-4">
              {this.state.error?.message || 'An unexpected error occurred'}
            </p>
            <button
              onClick={() => this.setState({ hasError: false, error: null })}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
            >
              Try Again
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
  children, 
  requiresTelemetry = false,
  isAuthenticated  // Add this prop
}) => {
  const [loading, setLoading] = useState(true);
  const [telemetryStatus, setTelemetryStatus] = useState<TelemetryStatus | null>(null);
  const location = useLocation();

  useEffect(() => {
    const checkTelemetry = async () => {
      try {
        if (isAuthenticated && requiresTelemetry) {
          // Get selected vehicle ID
          const vehicleId = localStorage.getItem('tesla_selected_vehicle');
          if (!vehicleId) {
            setTelemetryStatus({ status: 'disabled' });
            setLoading(false);
            return;
          }

          // Check telemetry status
          const user = auth.currentUser;
          if (!user) {
            setTelemetryStatus({ status: 'error' });
            setLoading(false);
            return;
          }

          const accessToken = await user.getIdToken();
          const response = await axios.get(
            `https://getcarma.app/api/vehicles/${vehicleId}/telemetry`,
            {
              headers: { Authorization: `Bearer ${accessToken}` }
            }
          );

          setTelemetryStatus({
            status: response.data.status,
            lastCheck: new Date().toISOString()
          });
        }
        setLoading(false);
      } catch (error) {
        console.error('Telemetry check failed:', error);
        setTelemetryStatus({ status: 'error' });
        setLoading(false);
      }
    };

    checkTelemetry();
  }, [isAuthenticated, requiresTelemetry]);

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700 mb-4"></div>
        <p className="text-gray-600">Checking telemetry status...</p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  if (requiresTelemetry && telemetryStatus) {
    if (telemetryStatus.status === 'disabled') {
      return <Navigate to="/telemetry/setup" state={{ from: location }} replace />;
    }

    if (telemetryStatus.status === 'error') {
      return <Navigate to="/telemetry/status" state={{ from: location }} replace />;
    }

    if (!localStorage.getItem('tesla_selected_vehicle')) {
      return <Navigate to="/choose-vehicle" state={{ from: location }} replace />;
    }
  }

  return (
    <React.Suspense 
      fallback={
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
        </div>
      }
    >
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default ProtectedRoute;