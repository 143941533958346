import React, { useState, FormEvent, forwardRef } from 'react';
import styled from 'styled-components';
import { addEmailToFirestore } from '../firebase/config'; // Adjust the path if needed

const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const Container = styled.div`
  background-color: #2030da;
  border-radius: 30px;
  padding: 40px 20px;
  color: white;
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
  z-index: 2;

  @media (min-width: ${breakpoints.sm}) {
    padding: 60px 30px;
    border-radius: 25px;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 80px 40px;
    border-radius: 30px;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 1200px;
    padding: 100px;
  }
`;

const Title = styled.h2`
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-weight: 900;
  margin-bottom: 15px;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 20px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  span {
    display: block;
    margin-top: 0rem;
  }
`;

const Description = styled.p`
  font-weight: 500;
  text-align: center;
  font-size: 1rem;
  max-width: 100%;
  margin: 0 auto 20px;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.1rem;
    max-width: 80%;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 60%;
    margin-bottom: 30px;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 50rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  padding: 10px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.sm}) {
    flex-direction: row;
    max-width: 500px;
    border-radius: 50px;
  }
`;

const Input = styled.input`
  width: 100%;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  outline: none;
  background: transparent;
  margin-left: 20px;
  margin-bottom: 10px;
  border-radius: 25px;

  @media (min-width: ${breakpoints.sm}) {
    width: auto;
    flex-grow: 1;
    margin-bottom: 0;
    border-radius: 0;
  }
`;

const Button = styled.button`
  background-color: #2030da;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    width: auto;
    border-radius: 50px;
  }
`;

interface SubscribeFormProps {}

const SubscribeForm = forwardRef<HTMLDivElement, SubscribeFormProps>((props, ref) => {
  const [email, setEmail] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Form submitted with email:', email);
    
    if (!validateEmail(email)) {
      console.log('Email validation failed');
      setError('Please enter a valid email address');
      return;
    }
    
    try {
      console.log('Attempting to add email to Firestore');
      await addEmailToFirestore(email);
      console.log('Email successfully added to Firestore');
      setIsSubmitted(true);
      setEmail('');
      setError('');
    } catch (error) {
      console.error('Error adding email to Firestore:', error);
      setError('There was an error submitting your email. Please try again.');
    }
    
    console.log('Form submission process completed');
  };

  return (
    <Container ref={ref}>
      <Title>
        DRIVE SMARTER, SAVE BIGGER
        <span>WITH CARMA</span>
      </Title>
      <Description>
        Be among the first to revolutionize your driving experience. 
        Get exclusive early access to our beta and start your journey towards better driving and potential insurance savings.
      </Description>
      {isSubmitted ? (
        <p>Buckle up! You're on the road to smarter driving and bigger savings.</p>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Enter your e-mail for the fast lane"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            required
          />
          <Button type="submit">Get Early Access</Button>
        </Form>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </Container>
  );
});

export default SubscribeForm;
