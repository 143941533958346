import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import homeImage from '../assets/drive9.png';
import tripsImage from '../assets/scoregood.png';
import drive2Image from '../assets/savebig.png';

// Define breakpoints
const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

interface OpacityProps {
  $opacity: number;
}

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem;
    flex-direction: row;
    justify-content: space-between;
    max-width: 90%;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 1200px;
  }
`;

const ContentSection = styled.div<OpacityProps>`
  width: 100%;
  opacity: ${props => props.$opacity};
  transition: opacity 0.8s ease-in-out;
  margin-bottom: 2rem;

  @media (min-width: ${breakpoints.md}) {
    flex: 1;
    max-width: 50%;
    margin-bottom: 0;
  }
`;

const Description2 = styled.p`
  font-size: 0.7rem;
  color: gray;
  font-weight: 500;
  margin-bottom: -1.5rem;
  line-height: 1.4;
  max-width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.05rem;
    margin-bottom: 1.75rem;
  }

  @media (min-width: ${breakpoints.md}) {
    display: none; // Hide for desktop view
  }
`;

const Title = styled.h2`
  font-size: 2.2rem;
  font-weight: 900;
  margin-bottom: 1rem;
  line-height: 1.2;

  @media (min-width: ${breakpoints.md}) {
    font-size: 2.5rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 3.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #000;
  font-weight: 500;
  margin-bottom: 1.5rem;
  line-height: 1.4;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
`;

const TryButton = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: #2030da;
  color: #fff;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    width: auto;
  }
`;

const RightSection = styled.div<OpacityProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${props => props.$opacity};
  transition: opacity 0.5s ease-in-out;

  @media (min-width: ${breakpoints.md}) {
    flex: 1;
    max-width: 40%;
  }
`;

const ContentImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
  border-radius: 30px;

  @media (min-width: ${breakpoints.md}) {
    width: 80%;
    margin-bottom: 2rem;
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  width: 70%;

  @media (min-width: ${breakpoints.sm}) {
    gap: 1rem;
  }
`;

interface NavButtonProps {
  $active: boolean;
}

const NavButton = styled.button<NavButtonProps>`
  padding: 0.5rem;
  font-size: 0.8rem;
  background-color: ${props => props.$active ? '#2030da' : 'transparent'};
  color: ${props => props.$active ? '#fff' : '#2030da'};
  border: 2px solid #2030da;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: 500;
  flex: 1;

  @media (min-width: ${breakpoints.sm}) {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    flex: 0 1 auto;
  }
`;

interface ContentItem {
  title: string;
  description: string;
  buttonText: string;
  image: string;
}

const contentData: ContentItem[] = [
  {
    title: "DRIVE SMARTER",
    description: "Carma runs silently in the background, giving you insights without distractions. Focus on the road, we'll handle the rest.",
    buttonText: "Get started",
    image: homeImage
  },
  {
    title: "SCORE HIGHER",
    description: "Track your Carma score, see how you stack up, and unlock your driving potential. Better scores mean better rates — it's that simple.",
    buttonText: "Check your score",
    image: tripsImage
  },
  {
    title: "SAVE BIGGER",
    description: "Shop for car insurance that rewards your skills. Your Carma score could slash your premiums. Drive well, spend less — win-win.",
    buttonText: "Start saving",
    image: drive2Image
  }
];

interface RotatingContentProps {
  scrollToSubscribeForm: () => void;
}

const RotatingContent: React.FC<RotatingContentProps> = ({ scrollToSubscribeForm }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const fadeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const rotateContent = useCallback(() => {
    setOpacity(0);
    fadeTimeoutRef.current = setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % contentData.length);
      setOpacity(1);
    }, 500);
  }, []);

  const resetInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(rotateContent, 5500);
  }, [rotateContent]);

  useEffect(() => {
    resetInterval();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
      }
    };
  }, [resetInterval]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavClick = (index: number) => {
    if (index !== activeIndex) {
      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      
      setOpacity(0);
      fadeTimeoutRef.current = setTimeout(() => {
        setActiveIndex(index);
        setOpacity(1);
        resetInterval();
      }, 500);
    }
  };

  const handleTryButtonClick = () => {
    scrollToSubscribeForm();
  };

  return (
    <Container>
      <ContentSection $opacity={opacity}>
        {isMobile && <Description2>HOW IT WORKS</Description2>}
        <Title>{contentData[activeIndex].title}</Title>
        <Description>{contentData[activeIndex].description}</Description>
        <TryButton onClick={handleTryButtonClick}>{contentData[activeIndex].buttonText}</TryButton>
      </ContentSection>
      <RightSection $opacity={opacity}>
        <ContentImage src={contentData[activeIndex].image} alt={contentData[activeIndex].title} />
        <NavigationButtons>
          <NavButton $active={activeIndex === 0} onClick={() => handleNavClick(0)}>Drive</NavButton>
          <NavButton $active={activeIndex === 1} onClick={() => handleNavClick(1)}>Score</NavButton>
          <NavButton $active={activeIndex === 2} onClick={() => handleNavClick(2)}>Save</NavButton>
        </NavigationButtons>
      </RightSection>
    </Container>
  );
};

export default RotatingContent;
