import React from 'react';
import styled from 'styled-components';
import heroImage from '../assets/city27.png'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';

// Define breakpoints
const breakpoints = {
  xs: '480px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const HeroContainer = styled.section`
  position: relative;
  height: 100vh;
  margin-top: 0;
  z-index: 1;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60%;
    background: linear-gradient(to bottom right, #2030da, #ffffff);
    z-index: -1;
  }
`;

const ContentOverlay = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  z-index: 2;
  padding: 1rem;

  @media (min-width: ${breakpoints.md}) {
    width: 80%;
  }
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: white;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-weight: 900;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.01);

  @media (min-width: ${breakpoints.xs}) {
    font-size: 1.8rem;
  }

  @media (min-width: ${breakpoints.sm}) {
    font-size: 2.2rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 12rem;
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  strong {
    font-weight: 900;
  }

  span {
    color: black;
  }
`;

const DesktopHeroTitle = styled(HeroTitle)`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: block;

    strong:first-child {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
`;

const MobileHeroTitle = styled(HeroTitle)`
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: black;
  margin-bottom: 1rem;
  font-weight: 500;
  text-shadow: 2px 2px 20px rgba(0, 0, 1, 0.2);

  @media (min-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
`;

const HeroButton = styled.button`
  padding: 0.8rem 2.5rem;
  font-size: 0.9rem;
  color: #ffffff;
  background-color: #2030da;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;

  @media (min-width: ${breakpoints.md}) {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%;
  z-index: 1;
`;

const HeroImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${heroImage});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Hero: React.FC = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/auth');
  };

  return (
    <HeroContainer>
      <ContentOverlay>
        <MobileHeroTitle>
          <strong>BETTER DRIVING MEAN </strong>
          <span><strong>BETTER INSURANCE RATES</strong></span>
        </MobileHeroTitle>
        <DesktopHeroTitle>
          <strong>BETTER DRIVING MEAN</strong>
          <span><strong>BETTER INSURANCE RATES</strong></span>
        </DesktopHeroTitle>
        <HeroSubtitle>Prove your skills on the road, watch your insurance costs drop.</HeroSubtitle>
        <HeroButton onClick={handleGetStarted}>Get started</HeroButton>
      </ContentOverlay>
      <ImageContainer>
        <HeroImage />
      </ImageContainer>
    </HeroContainer>
  );
};

export default Hero;