import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Types
interface TeslaVehicle {
  id: string;
  vehicle_id: number;
  vin: string;
  display_name: string;
  state: string;
  in_service: boolean;
  telemetry_status?: {
    status: 'enabled' | 'disabled' | 'pending';
    error?: string;
  };
  telemetry_enabled?: boolean;
}

interface GetVehiclesResponse {
  response: TeslaVehicle[];
}

const ChooseVehiclePage: React.FC = () => {
  const [vehicles, setVehicles] = useState<TeslaVehicle[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const accessToken = localStorage.getItem('tesla_access_token');
        if (!accessToken) throw new Error('No access token found');

        const response = await axios.get<GetVehiclesResponse>('https://getcarma.app/api/vehicles', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        setVehicles(response.data.response || []);
      } catch (err) {
        console.error('Vehicle fetch error:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch vehicles');
        
        // If the error is due to an invalid token, try to refresh it
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          try {
            const refreshToken = localStorage.getItem('tesla_refresh_token');
            if (refreshToken) {
              const refreshResponse = await axios.post('https://getcarma.app/api/auth/refresh-token', {
                refreshToken
              });
              
              localStorage.setItem('tesla_access_token', refreshResponse.data.accessToken);
              if (refreshResponse.data.refreshToken) {
                localStorage.setItem('tesla_refresh_token', refreshResponse.data.refreshToken);
              }
              
              // Retry fetching vehicles with new token
              const vehiclesResponse = await axios.get<GetVehiclesResponse>('https://getcarma.app/api/vehicles', {
                headers: {
                  Authorization: `Bearer ${refreshResponse.data.accessToken}`
                }
              });
              
              setVehicles(vehiclesResponse.data.response || []);
              setError(null);
            }
          } catch (refreshErr) {
            console.error('Token refresh error:', refreshErr);
            navigate('/connect-car');
          }
        }
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [navigate]);

  const handleVehicleSelect = async (vehicleId: string) => {
    try {
      const accessToken = localStorage.getItem('tesla_access_token');
      if (!accessToken) throw new Error('No access token found');

      // Enable telemetry for the vehicle
      await axios.post(
        `https://getcarma.app/api/vehicles/${vehicleId}/telemetry/enable`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      localStorage.setItem('tesla_selected_vehicle', vehicleId);
      navigate('/dashboard');
    } catch (err) {
      console.error('Vehicle selection/telemetry error:', err);
      setError('Failed to setup vehicle telemetry');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center space-x-3">
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
            <p>Loading vehicles...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white rounded-lg shadow-md p-6 max-w-md w-full">
          <h2 className="text-xl font-semibold text-red-600 mb-2">Error</h2>
          <p className="text-gray-600 mb-4">{error}</p>
          <button onClick={() => navigate('/connect-car')} className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors">
            Reconnect Tesla Account
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-2xl">
        <h1 className="text-2xl font-bold text-center mb-6">Choose Vehicle</h1>
        <div className="space-y-4">
          {vehicles.length === 0 ? (
            <p className="text-center text-gray-500">No vehicles found</p>
          ) : (
            vehicles.map(vehicle => (
              <button
                key={vehicle.id}
                onClick={() => handleVehicleSelect(vehicle.id)}
                className="w-full p-4 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="flex justify-between items-center">
                  <span>{vehicle.display_name || 'Tesla Vehicle'}</span>
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-500">{vehicle.vin}</span>
                    {vehicle.telemetry_enabled ? (
                      <span className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded">
                        Telemetry Active
                      </span>
                    ) : (
                      <span className="px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded">
                        Setup Required
                      </span>
                    )}
                  </div>
                </div>
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseVehiclePage;