import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  margin-top: 4rem;
  background-color: #f9f9f9;
  padding: 40px 20px;
  color: #333;
  z-index: 1;
`;

const FooterContent = styled.div`
  padding-top: 0rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LogoSection = styled.div`
  flex: 1 1 300px;
  margin-bottom: 20px;
`;

const Logo = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin: 40px 0 10px 0;
`;

const MissionStatement = styled.p`
  font-size: 14px;
  line-height: 1.5;
  max-width: 300px;
`;

const ContactSection = styled.div`
  flex: 1 1 300px;
  margin-bottom: 20px;
`;

const ContactInfo = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-top: 20px;
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  color: #666;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoSection>
          <Logo>Carma</Logo>
          <MissionStatement>
            At Carma, we're dedicated to making roads safer and driving more rewarding. Our goal is to empower drivers with insights that lead to better habits and potentially lower insurance costs.
          </MissionStatement>
          <ContactInfo>
            <p>Contact us: info@getcarma.app</p>
            <p>Registration nr: 14664407</p>
            <p>Aadress: Pääsusilma tee 4, Tallinn, Estonia, 12014</p>
          </ContactInfo>
        </LogoSection>
      </FooterContent>
      <Copyright>
        © 2024 Carma Telematics OÜ. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;