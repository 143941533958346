import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService, ApiError } from '../../api/config';
import { isAxiosError } from 'axios';

const TeslaCallback: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const handleCallback = async () => {
      try {
        console.log('Starting callback handling');
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const savedState = localStorage.getItem('tesla_oauth_state');

        console.log('Received code:', code);
        console.log('Received state:', state);
        console.log('Saved state:', savedState);

        if (!code) {
          throw new Error('No authorization code received');
        }

        if (!savedState || state !== savedState) {
          throw new Error('Invalid OAuth state - possible security breach');
        }

        // Exchange code for tokens using the API service
        const response = await apiService.auth.exchangeCode(code);

        if (!response.data.accessToken) {
          throw new Error('No access token in response');
        }

        // Store tokens
        localStorage.setItem('tesla_access_token', response.data.accessToken);
        if (response.data.refreshToken) {
          localStorage.setItem('tesla_refresh_token', response.data.refreshToken);
        }
        localStorage.removeItem('tesla_oauth_state');

        // Navigate to vehicle selection
        navigate('/choose-vehicle');
      } catch (err) {
        console.error('Tesla callback error:', err);
        
        let errorMessage = 'Failed to connect to Tesla';
        if (err instanceof ApiError) {
          errorMessage = err.message;
        } else if (isAxiosError(err) && err.response?.data?.error) {
          errorMessage = err.response.data.error;
        } else if (err instanceof Error) {
          errorMessage = err.message;
        }
        
        setError(errorMessage);
      }
    };

    handleCallback();
  }, [navigate]);

  // Rest of your component remains the same...
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full m-4">
          <h2 className="text-xl font-semibold text-red-600 mb-4">Connection Error</h2>
          <p className="text-gray-600 mb-4">{error}</p>
          <div className="space-y-3">
            <button
              onClick={() => navigate('/connect-car')}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
            >
              Try Again
            </button>
            <button
              onClick={() => navigate('/')}
              className="w-full bg-gray-200 text-gray-700 py-2 px-4 rounded hover:bg-gray-300 transition-colors"
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full m-4">
        <div className="flex flex-col items-center space-y-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          <p className="text-gray-600">Connecting to Tesla...</p>
        </div>
      </div>
    </div>
  );
};

export default TeslaCallback;