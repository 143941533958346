import React from 'react';
import styled from 'styled-components';
import securePhoneIcon from '../assets/device3.png';
import badImage from '../assets/arrowdown2.png';
import chart from '../assets/chart.png';
import steering from '../assets/steering.png';

const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  text-align: left;

  @media (min-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem;
    text-align: center;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 2rem;
  }
`;

const Title = styled.h2`
  font-size: 2.2rem;
  font-weight: 900;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  line-height: 1;
  text-align: left;

  @media (min-width: ${breakpoints.md}) {
    font-size: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 3.5rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem; // Reduced gap for mobile

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
  }
`;

const Card = styled.div`
  background-color: #f9f9f9;
  padding: 1.25rem; // Slightly reduced padding for mobile
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem; // Reduced margin for mobile

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem;
    border-radius: 30px;
    margin-bottom: 2rem;
    align-items: center;
  }
`;

const IconWrapper = styled.div`
  width: 180px; // Increased size for mobile
  height: 180px; // Increased size for mobile
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  align-self: center;

  @media (min-width: ${breakpoints.md}) {
    width: 200px;
    height: 200px;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 250px;
    height: 250px;
  }
`;

const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const TextContent = styled.div`
  width: 100%;
  text-align: left;

  @media (min-width: ${breakpoints.md}) {
    text-align: center;
  }
`;

const CardTitle = styled.h3`
  margin-top: -1rem;
  font-size: 1.2rem; // Slightly increased for mobile
  margin-bottom: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.2rem;
    margin-top: -1.5rem;
  }
`;

const CardDescription = styled.p`
  font-size: 1.1rem; // Increased for mobile
  color: #666;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

const DrivingFeatures: React.FC = () => {
  const features = [
    {
      icon: steering,
      title: "Drive Analysis",
      description: "Get insights on your driving patterns and habits"
    },
    {
      icon: chart,
      title: "Score Tracking",
      description: "Monitor your driving score and see improvements over time"
    },
    {
      icon: badImage,
      title: "Insurance Savings",
      description: "Use your good driving score to potentially lower insurance costs"
    },
    {
      icon: securePhoneIcon,
      title: "Data Privacy",
      description: "Your driving data stays on your device, under your control"
    }
  ];

  return (
    <Container>
      <Title>SO MANY WAYS TO IMPROVE YOUR DRIVING</Title>
      <Grid>
        {features.map((feature, index) => (
          <Card key={index}>
            <IconWrapper>
              <Icon src={feature.icon} alt={feature.title} />
            </IconWrapper>
            <TextContent>
              <CardTitle>{feature.title}</CardTitle>
              <CardDescription>{feature.description}</CardDescription>
            </TextContent>
          </Card>
        ))}
      </Grid>
    </Container>
  );
};

export default DrivingFeatures;