import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTelemetry } from './TelemetryContext';

const TelemetrySetupPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { enableTelemetry } = useTelemetry();

  const handleSetupTelemetry = async () => {
    setLoading(true);
    try {
      const vehicleId = localStorage.getItem('tesla_selected_vehicle');
      if (!vehicleId) throw new Error('No vehicle selected');

      await enableTelemetry(vehicleId);
      navigate('/dashboard');
    } catch (err) {
      console.error('Telemetry setup error:', err);
      setError('Failed to setup telemetry. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
        <h1 className="text-2xl font-bold text-center mb-6">Setup Telemetry</h1>
        
        <div className="space-y-4">
          <p className="text-gray-600">
            To monitor your Tesla, we need to enable telemetry data collection.
            This will allow us to receive real-time updates from your vehicle.
          </p>

          {error && (
            <div className="p-3 bg-red-50 text-red-600 rounded-lg text-sm">
              {error}
            </div>
          )}

          <button
            onClick={handleSetupTelemetry}
            disabled={loading}
            className={`w-full py-2 px-4 rounded transition-colors ${
              loading 
                ? 'bg-gray-300 cursor-not-allowed' 
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
          >
            {loading ? (
              <div className="flex items-center justify-center space-x-2">
                <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                <span>Setting up...</span>
              </div>
            ) : (
              'Enable Telemetry'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TelemetrySetupPage;