import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface ScrollProps {
  isScrolled: boolean;
}

interface MobileMenuProps {
  isOpen: boolean;
}

const HeaderContainer = styled.header<ScrollProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.8);
    z-index: -1;
    opacity: ${props => props.isScrolled ? 1 : 0};
    transition: opacity 0.3s ease;
  }

  @media (min-width: 768px) {
    padding: 20px 40px;
  }
`;

const Logo = styled.div<ScrollProps>`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.isScrolled ? '#2030da' : 'white'};
  transition: color 0.3s ease;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MainNav = styled.nav<ScrollProps>`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    margin: 0 20px;

    a {
      margin: 0 15px;
      text-decoration: none;
      color: ${props => props.isScrolled ? 'black' : 'white'};
      font-size: 16px;
      transition: color 0.3s ease, opacity 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const LangSelector = styled.div<ScrollProps>`
  display: none;

  @media (min-width: 768px) {
    display: block;
    a {
      text-decoration: none;
      color: ${props => props.isScrolled ? 'black' : 'white'};
      font-size: 16px;
      transition: color 0.3s ease, opacity 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const HamburgerIcon = styled.div<ScrollProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    width: 25px;
    background: ${props => props.isScrolled ? 'black' : 'white'};
    margin-bottom: 4px;
    transition: background-color 0.3s ease;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div<MobileMenuProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px;
  background-color: white;
  transform: ${props => props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease-in-out;
  padding: 60px 20px 20px;
  z-index: 999;

  a {
    display: block;
    margin: 20px 0;
    text-decoration: none;
    color: black;
    font-size: 18px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <HeaderContainer isScrolled={isScrolled}>
        <Logo isScrolled={isScrolled}>Carma</Logo>
        <NavContainer>
          <MainNav isScrolled={isScrolled}>
          </MainNav>
          <LangSelector isScrolled={isScrolled}>
            <a href="#eng">ENG</a>
          </LangSelector>
        </NavContainer>
      </HeaderContainer>
    </>
  );
};

export default Header;