import React from 'react';
import styled from 'styled-components';
import phoneImage from '../assets/cockpit.png'; // Make sure to add this image to your assets

const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  max-width: 100%;
  margin: 0 auto;
  
  @media (min-width: ${breakpoints.sm}) {
    padding: 3rem 1.5rem;
  }
  
  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 2rem;
  }
  
  @media (min-width: ${breakpoints.xl}) {
    max-width: 1200px;
  }
`;

const Content = styled.div`
  flex: 1;
  max-width: 100%;
  order: 1;
  
  @media (min-width: ${breakpoints.md}) {
    max-width: 50%;
    padding-right: 2rem;
    order: 0;
  }
`;

const Title = styled.h2`
  font-size: 2.2rem;
  line-height: 1rem;
  font-weight: 900;
  margin-bottom: 1rem;
  line-height: 1;
  
  @media (min-width: ${breakpoints.sm}) {
    font-size: 2.5rem;
  }
  
  @media (min-width: ${breakpoints.md}) {
    font-size: 3rem;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    font-size: 3.5rem;
  }
  
  strong {
    display: block;
    color: #000;
    font-weight: 900;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  color: #000;
  font-weight: 500;
  line-height: 1.4;
  
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
`;

const ListItem = styled.li`
  font-size: 1rem;
  color: #000;
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 1.4;
  position: relative;
  padding-left: 1.5rem;
  
  &::before {
    content: "-";
    position: absolute;
    left: 0;
  }
  
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 1.5rem;
  }
`;

const Button = styled.button`
  padding: 0.75rem 2rem;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  background-color: #3040e0;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  
  &:hover {
    background-color: #2030da;
  }
  
  @media (min-width: ${breakpoints.sm}) {
    width: auto;
  }
`;

const PhoneImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 2rem;
  border-radius: 30px;
  order: 2;
  
  @media (min-width: ${breakpoints.sm}) {
    max-width: 80%;
  }
  
  @media (min-width: ${breakpoints.md}) {
    max-width: 45%;
    margin-top: 0;
    margin-left: 2rem;
    order: 0;
  }
`;

interface LearnAndSaveProps {
  scrollToSubscribeForm: () => void;
}

const LearnAndSave: React.FC<LearnAndSaveProps> = ({ scrollToSubscribeForm }) => {
  return (
    <Container>
      <Content>
        <Title>
          <strong>PAY LESS, DRIVE SMARTER</strong>
        </Title>
        <List>
          Carma tailors your insurance to your driving style. Our app quietly analyzes your habits, helps you improve, and could slash your premiums. Better driving, lower costs — it's insurance that moves with you.
        </List>
        <Button onClick={scrollToSubscribeForm}>Get started</Button>
      </Content>
      <PhoneImage src={phoneImage} alt="Carma app screenshot" />
    </Container>
  );
};

export default LearnAndSave;
