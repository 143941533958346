import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { 
  getAuth, 
  GoogleAuthProvider, 
  signInWithPopup,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD6eJNdrub7ZiidosFsHGlOB681Ghcj5w4",
  authDomain: "carma-telematics.firebaseapp.com",
  projectId: "carma-telematics",
  storageBucket: "carma-telematics.appspot.com",
  messagingSenderId: "749598359907",
  appId: "1:749598359907:web:d0f22f939a03bba10ed356",
  measurementId: "G-JKJ9959CRL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Configure persistence
setPersistence(auth, browserLocalPersistence);

// Configure Google provider
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

// Google Sign In function
export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    console.log('Successfully signed in:', user);
    return user;
  } catch (error: any) {
    console.error('Error during sign in:', error);
    if (error.code === 'auth/popup-closed-by-user') {
      throw new Error('Sign-in popup was closed before completing the process');
    } else if (error.code === 'auth/popup-blocked') {
      throw new Error('Sign-in popup was blocked by the browser. Please enable popups for this site.');
    } else {
      throw error;
    }
  }
};

export const addEmailToFirestore = async (email: string): Promise<string> => {
  try {
    const docRef = await addDoc(collection(db, "emails"), {
      email: email,
      timestamp: new Date()
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};

export { auth, db };