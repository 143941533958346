import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface TelemetryStatus {
  status: 'enabled' | 'disabled' | 'error';
  lastUpdate?: string;
  error?: string;
}

const TelemetryStatusPage: React.FC = () => {
  const [status, setStatus] = useState<TelemetryStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const vehicleId = localStorage.getItem('tesla_selected_vehicle');
        const accessToken = localStorage.getItem('tesla_access_token');
        
        if (!vehicleId || !accessToken) {
          throw new Error('Missing vehicle or authentication');
        }

        const response = await axios.get(
          `https://getcarma.app/api/vehicles/${vehicleId}/telemetry`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        );

        setStatus(response.data);
      } catch (err) {
        console.error('Status check error:', err);
        setStatus({ status: 'error', error: 'Failed to check telemetry status' });
      } finally {
        setLoading(false);
      }
    };

    checkStatus();
  }, []);

  const getStatusDisplay = () => {
    if (!status) return null;

    switch (status.status) {
      case 'enabled':
        return (
          <div className="p-4 bg-green-50 text-green-700 rounded-lg">
            <h3 className="font-semibold">Telemetry Active</h3>
            {status.lastUpdate && (
              <p className="text-sm mt-1">
                Last update: {new Date(status.lastUpdate).toLocaleString()}
              </p>
            )}
          </div>
        );
      case 'disabled':
        return (
          <div className="p-4 bg-yellow-50 text-yellow-700 rounded-lg">
            <h3 className="font-semibold">Telemetry Disabled</h3>
            <button
              onClick={() => navigate('/telemetry/setup')}
              className="mt-2 text-sm bg-yellow-200 px-3 py-1 rounded"
            >
              Enable Telemetry
            </button>
          </div>
        );
      case 'error':
        return (
          <div className="p-4 bg-red-50 text-red-700 rounded-lg">
            <h3 className="font-semibold">Telemetry Error</h3>
            <p className="text-sm mt-1">{status.error}</p>
            <button
              onClick={() => navigate('/telemetry/setup')}
              className="mt-2 text-sm bg-red-200 px-3 py-1 rounded"
            >
              Retry Setup
            </button>
          </div>
        );
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full" />
      </div>
    );
  }

  return (
    <div className="p-6 max-w-lg mx-auto">
      <h1 className="text-2xl font-bold mb-6">Telemetry Status</h1>
      {getStatusDisplay()}
    </div>
  );
};

export default TelemetryStatusPage;