import React, { createContext, useContext, } from 'react';
import axios from 'axios';

interface TelemetryContextType {
  enableTelemetry: (vehicleId: string) => Promise<void>;
  disableTelemetry: (vehicleId: string) => Promise<void>;
  checkTelemetryStatus: (vehicleId: string) => Promise<boolean>;
}

const TelemetryContext = createContext<TelemetryContextType | null>(null);

export const TelemetryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const enableTelemetry = async (vehicleId: string) => {
    const accessToken = localStorage.getItem('tesla_access_token');
    if (!accessToken) throw new Error('No access token found');

    await axios.post(
      `https://getcarma.app/api/vehicles/${vehicleId}/telemetry/enable`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
  };

  const disableTelemetry = async (vehicleId: string) => {
    const accessToken = localStorage.getItem('tesla_access_token');
    if (!accessToken) throw new Error('No access token found');

    await axios.post(
      `https://getcarma.app/api/vehicles/${vehicleId}/telemetry/disable`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
  };

  const checkTelemetryStatus = async (vehicleId: string) => {
    const accessToken = localStorage.getItem('tesla_access_token');
    if (!accessToken) throw new Error('No access token found');

    const response = await axios.get(
      `https://getcarma.app/api/vehicles/${vehicleId}/telemetry`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response.data?.status === 'enabled';
  };

  return (
    <TelemetryContext.Provider value={{ enableTelemetry, disableTelemetry, checkTelemetryStatus }}>
      {children}
    </TelemetryContext.Provider>
  );
};

export const useTelemetry = () => {
  const context = useContext(TelemetryContext);
  if (!context) {
    throw new Error('useTelemetry must be used within a TelemetryProvider');
  }
  return context;
};