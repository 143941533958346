// src/api/config.ts
import axios, { AxiosError } from 'axios';

const API_BASE = `${process.env.REACT_APP_API_URL}/api`;

// Types
export interface ApiResponse<T = any> {
  data: T;
  status: number;
  message?: string;
}

// Add error response type
export interface ApiErrorResponse {
  error: string;
  details?: any;
  status?: number;
  message?: string;
}

export interface TeslaAuthResponse {
  accessToken: string;
  refreshToken?: string;
  expiresIn: number;
}

export interface VehicleResponse {
  vehicles: Array<{
    id: string;
    vin: string;
    display_name: string;
    state: string;
    telemetry_enabled?: boolean;
  }>;
  meta?: {
    total: number;
    with_telemetry: number;
    timestamp: string;
  };
}

export interface TelemetryResponse {
  status: 'enabled' | 'disabled' | 'error';
  lastUpdate?: string;
  error?: string;
}

// Updated custom error class
export class ApiError extends Error {
  constructor(
    message: string,
    public status?: number,
    public response?: ApiErrorResponse
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

// Create axios instance
const api = axios.create({
  baseURL: API_BASE,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('tesla_access_token');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError<ApiErrorResponse>) => {
    return Promise.reject(new ApiError(
      error.message,
      error.response?.status,
      error.response?.data
    ));
  }
);

// Response interceptor with proper typing
api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<ApiErrorResponse>) => {
    // Handle 401 and token refresh
    if (error.response?.status === 401) {
      const refreshToken = localStorage.getItem('tesla_refresh_token');
      if (refreshToken) {
        try {
          const response = await api.post<ApiResponse<TeslaAuthResponse>>(
            '/auth/refresh-token',
            { refreshToken }
          );
          
          localStorage.setItem('tesla_access_token', response.data.data.accessToken);
          if (response.data.data.refreshToken) {
            localStorage.setItem('tesla_refresh_token', response.data.data.refreshToken);
          }
          
          // Retry the original request
          const originalRequest = error.config;
          if (originalRequest && originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${response.data.data.accessToken}`;
            return api(originalRequest);
          }
        } catch (refreshError) {
          localStorage.removeItem('tesla_access_token');
          localStorage.removeItem('tesla_refresh_token');
          window.location.href = '/auth';
        }
      }
    }
    
    return Promise.reject(
      new ApiError(
        error.response?.data?.error || error.message,
        error.response?.status,
        error.response?.data
      )
    );
  }
);

// API endpoints configuration
const endpoints = {
  auth: {
    exchangeCode: '/auth/exchange-code',
    refreshToken: '/auth/refresh-token',
    partnerToken: '/auth/partner-token'
  },
  vehicles: {
    list: '/vehicles',
    telemetry: (id: string) => `/vehicles/${id}/telemetry`,
    enableTelemetry: (id: string) => `/vehicles/${id}/telemetry/enable`,
    disableTelemetry: (id: string) => `/vehicles/${id}/telemetry/disable`
  }
};

// API service methods with proper error handling
export const apiService = {
  auth: {
    exchangeCode: async (code: string): Promise<ApiResponse<TeslaAuthResponse>> => {
      try {
        const response = await api.post<ApiResponse<TeslaAuthResponse>>(
          endpoints.auth.exchangeCode,
          { code }
        );
        return response.data;
      } catch (error) {
        if (error instanceof ApiError) {
          throw error;
        }
        if (axios.isAxiosError(error) && error.response?.data) {
          throw new ApiError(
            error.response.data.error || 'Failed to exchange code',
            error.response.status,
            error.response.data
          );
        }
        throw new ApiError('Failed to exchange code', 500);
      }
    },

    refreshToken: async (refreshToken: string): Promise<ApiResponse<TeslaAuthResponse>> => {
      const response = await api.post<ApiResponse<TeslaAuthResponse>>(
        endpoints.auth.refreshToken,
        { refreshToken }
      );
      return response.data;
    }
  },

  vehicles: {
    getAll: async (): Promise<ApiResponse<VehicleResponse>> => {
      const response = await api.get<ApiResponse<VehicleResponse>>(endpoints.vehicles.list);
      return response.data;
    },

    getTelemetry: async (id: string): Promise<ApiResponse<TelemetryResponse>> => {
      const response = await api.get<ApiResponse<TelemetryResponse>>(
        endpoints.vehicles.telemetry(id)
      );
      return response.data;
    },

    enableTelemetry: async (id: string): Promise<ApiResponse<void>> => {
      const response = await api.post<ApiResponse<void>>(
        endpoints.vehicles.enableTelemetry(id)
      );
      return response.data;
    },

    disableTelemetry: async (id: string): Promise<ApiResponse<void>> => {
      const response = await api.post<ApiResponse<void>>(
        endpoints.vehicles.disableTelemetry(id)
      );
      return response.data;
    }
  }
};

export default api;