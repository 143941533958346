import React from 'react';
import styled from 'styled-components';
import scoreGoodImage from '../assets/quote2.png';
import badImage from '../assets/shield.png';
import safeImage from '../assets/safe2.png';
import { FaLock, FaUserShield, FaChartLine } from 'react-icons/fa';

const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const Container = styled.section`
  max-width: 100%;
  margin: 20px auto;
  padding: 1rem;
  text-align: left;
  background-color: white;

  @media (min-width: ${breakpoints.md}) {
    text-align: center;
    padding: 2rem;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 1200px;
  }
`;

const Title = styled.h2`
  font-size: 2.2rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  line-height: 1;
  color: black;

  @media (min-width: ${breakpoints.md}) {
    font-size: 2.5rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 3.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #000;
  font-weight: 500;
  margin-bottom: 2rem;
  line-height: 1.4;
  max-width: 100%;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.1rem;
    margin-bottom: 2.5rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-top: 1.5rem;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 0.9fr 1.2fr 0.9fr;
  }
`;

interface GridItemProps {
  isMiddle?: boolean;
  isSide?: boolean;
}

const GridItem = styled.div<GridItemProps>`
  position: relative;
  border-radius: 30px;
  padding: 20px;
  overflow: hidden;
  aspect-ratio: auto;
  background-color: ${props => props.isMiddle ? '#2030da' : '#f3f6fe'};
  display: ${props => props.isMiddle ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    aspect-ratio: ${props => props.isMiddle ? '4 / 3.2' : 'auto'};
    justify-content: space-between;
  }
`;

interface ItemImageWrapperProps {
  isMiddle?: boolean;
  isSide?: boolean;
}

const ItemImageWrapper = styled.div<ItemImageWrapperProps>`
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: -9rem;
  margin-top: 1rem;

  @media (min-width: ${breakpoints.md}) {
    align-items: ${props => props.isSide ? 'center' : 'flex-start'};
    margin-bottom: ${props => props.isSide ? '0' : '-9rem'};
    margin-top: ${props => props.isSide ? '1rem' : '1rem'};
    flex-grow: ${props => props.isSide ? '1' : '0'};
  }
`;

interface ItemImageProps {
  isSide?: boolean;
}

const ItemImage = styled.img<ItemImageProps>`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center top;

  @media (min-width: ${breakpoints.md}) {
    object-fit: contain;
    max-height: ${props => props.isSide ? '200px' : 'none'};
  }
`;

interface ItemContentProps {
  isMiddle?: boolean;
}

const ItemContent = styled.div<ItemContentProps>`
  padding: 0 1rem 1rem;
  color: ${props => props.isMiddle ? 'white' : 'black'};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: ${breakpoints.md}) {
    padding: ${props => props.isMiddle ? '0 1rem 1rem' : '0 1rem'};
  }
`;

const ItemIcon = styled.div<ItemContentProps>`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  align-self: flex-start;
  color: ${props => props.isMiddle ? 'white' : 'black'};
`;

const ItemText = styled.p<ItemContentProps>`
  font-weight: 500;
  text-align: left;
  font-size: 1rem;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: ${props => props.isMiddle ? '0rem' : '0'};
  color: ${props => props.isMiddle ? 'white' : 'black'};

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.1rem;
  }
`;

const CenteredBlock: React.FC = () => {
  return (
    <Container>
      <Title>YOUR DATA, YOUR CONTROL</Title>
      <Description>
        Carma keeps your info locked down tight on your device. Share your score
        when you want, keep it private when you don't. It's your driving data — we
        just crunch the numbers, you call the shots.
      </Description>
      <Grid>
        <GridItem isSide>
          <ItemContent>
            <ItemIcon><FaLock /></ItemIcon>
            <ItemText>Your driving data stays secure on your device</ItemText>
          </ItemContent>
          <ItemImageWrapper isSide>
            <ItemImage src={safeImage} alt="Safe" isSide />
          </ItemImageWrapper>
        </GridItem>
        <GridItem isMiddle>
          <ItemContent isMiddle>
            <ItemIcon isMiddle><FaUserShield /></ItemIcon>
            <ItemText isMiddle>Share your scores only when you choose to</ItemText>
          </ItemContent>
          <ItemImageWrapper isMiddle>
            <ItemImage src={scoreGoodImage} alt="Data sharing control" />
          </ItemImageWrapper>
        </GridItem>
        <GridItem isSide>
          <ItemContent>
            <ItemIcon><FaChartLine /></ItemIcon>
            <ItemText>Bad scores won't increase your current insurance rates</ItemText>
          </ItemContent>
          <ItemImageWrapper isSide>
            <ItemImage src={badImage} alt="Bad score" isSide />
          </ItemImageWrapper>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default CenteredBlock;