import React from 'react';
import styled from 'styled-components';

// Define breakpoints
const breakpoints = {
  xs: '480px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  width: 90%;
  max-width: 800px;
  margin: 3rem auto 1rem;
  text-align: center;
  margin-top: -2rem;

  @media (min-width: ${breakpoints.sm}) {
    padding: 3rem 1.5rem;
    margin: 4rem auto 1.5rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 4rem 2rem;
    margin: 5rem auto 2rem;
  }
`;

const Title = styled.h2`
  font-size: 2.2rem;
  font-weight: 900;
  margin-bottom: 0.75rem;
  line-height: 1.2;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 3rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 3.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #000;
  font-weight: 500;
  margin-bottom: 1.5rem;
  line-height: 1.4;
  max-width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.05rem;
    margin-bottom: 1.75rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    max-width: 80%;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 600px;
  }
`;

const CenteredBlock: React.FC = () => {
  return (
    <Container>
      <Title>OUR MISSION</Title>
      <Description>
        At Carma, we're dedicated to making roads safer and driving more rewarding. Our goal is to empower drivers with insights that lead to better habits and potentially lower insurance costs.
      </Description>
    </Container>
  );
};

export default CenteredBlock;