import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

// Define breakpoints
const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

// Define types for props
interface PlusIconProps {
  isActive: boolean;
}

interface AnswerProps {
  height: number;
}

const FAQContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 1200px;
  }
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 1.5rem;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

const Subtitle = styled.h1`
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 2rem;
  line-height: 1.2;
  text-align: center;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 2.5rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 3rem;
    margin-bottom: 2.5rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }
`;

const FAQContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 4rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 5rem;
  }
`;

const QuestionContainer = styled.div`
  width: 100%;
  padding: 0.75rem 0;
  border-top: 1px solid #e0e0e0;

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem 0;
  }

  &:last-child {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Question = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  margin: 0;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.1rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.2rem;
  }
`;

const PlusIcon = styled.span<PlusIconProps>`
  font-size: 1.25rem;
  font-weight: normal;
  color: #000;
  transition: transform 0.3s ease;
  transform: ${props => props.isActive ? 'rotate(45deg)' : 'rotate(0)'};

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.5rem;
  }
`;

const Answer = styled.div<AnswerProps>`
  font-size: 0.9rem;
  color: #000;
  font-weight: 500;
  line-height: 1.4;
  height: ${props => props.height}px;
  opacity: ${props => props.height > 0 ? 1 : 0};
  overflow: hidden;
  transition: height 0.5s ease-out, opacity 0.5s ease-out;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 0.95rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

const AnswerContent = styled.div`
  padding-top: 0.75rem;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 1rem;
  }
`;

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [heights, setHeights] = useState<number[]>([]);
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  const faqs = [
    {
      question: "How does Carma work?",
      answer: "Carma is an app that analyzes your driving habits to help you improve and potentially lower your insurance costs. It quietly runs in the background, collecting data on your driving patterns and providing insights to help you become a safer driver."
    },
    {
      question: "Is my driving data secure?",
      answer: "Yes, your driving data is kept secure on your device. Carma values your privacy, and you have full control over your information. You can choose when to share your score, and your data is not shared without your permission."
    },
    {
      question: "Can bad scores increase my current insurance rates?",
      answer: "No, bad scores won't increase your current insurance rates. Carma is designed to help you improve your driving and potentially save money. Your current rates won't be negatively affected by using the app."
    },
    {
      question: "How can I improve my driving score?",
      answer: "Carma provides several ways to improve your driving score. You'll get insights on your driving patterns and habits through our Drive Analysis feature. You can also track your score over time and see improvements. By following the app's suggestions and driving more safely, you can work on increasing your score."
    },
    {
      question: "How does Carma help me save on insurance?",
      answer: "Carma allows you to shop for car insurance that rewards your good driving skills. Your Carma score could potentially lead to lower premiums. By demonstrating safe driving habits, you may qualify for better rates with insurance providers who partner with Carma."
    },
    {
      question: "What kind of driving data does Carma collect?",
      answer: "Carma collects data on various aspects of your driving, such as speed, acceleration, braking, and overall patterns. This information is used to provide you with insights and calculate your driving score. Remember, this data stays on your device unless you choose to share it."
    }
  ];

  useEffect(() => {
    setHeights(refs.current.map(ref => ref ? ref.scrollHeight : 0));
  }, [faqs]);

  const toggleAnswer = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQContainer>
      <Subtitle>FREQUENTLY ASKED QUESTIONS</Subtitle>
      <FAQContent>
        {faqs.map((faq, index) => (
          <QuestionContainer key={index}>
            <QuestionHeader onClick={() => toggleAnswer(index)}>
              <Question>{faq.question}</Question>
              <PlusIcon isActive={activeIndex === index}>+</PlusIcon>
            </QuestionHeader>
            <Answer height={activeIndex === index ? heights[index] : 0}>
              <AnswerContent ref={el => refs.current[index] = el}>
                <p>{faq.answer}</p>
              </AnswerContent>
            </Answer>
          </QuestionContainer>
        ))}
      </FAQContent>
    </FAQContainer>
  );
};

export default FAQ;