import React, { useState } from 'react';

// Utility function for generating a secure random string (used for OAuth state)
const generateRandomString = (length: number): string => {
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

// Constants
const TESLA_AUTH_URL = 'https://auth.tesla.com/oauth2/v3/authorize';
const TESLA_FLEET_API_URL = process.env.REACT_APP_FLEET_API_URL;


// Connect Car Page Component
const ConnectCarPage: React.FC = () => {
  const [error, setError] = useState<string | null>(null);

  const handleConnectTesla = () => {
    try {
      // Generate a random string for the OAuth state parameter
      const state = generateRandomString(32);
      localStorage.setItem('tesla_oauth_state', state);

      // Define query parameters for Tesla OAuth
      const params = new URLSearchParams({
        response_type: 'code',
        client_id: process.env.REACT_APP_CLIENT_ID || '',
        redirect_uri: 'https://getcarma.app/auth/callback',
        scope: 'openid offline_access user_data vehicle_device_data vehicle_cmds vehicle_charging_cmds',
        state: state,
        prompt: 'login',
        audience: TESLA_FLEET_API_URL || ''
      });

      // Redirect to Tesla's OAuth authorization URL
      window.location.href = `${TESLA_AUTH_URL}?${params.toString()}`;
    } catch (err) {
      console.error('Tesla connect error:', err);
      setError('Failed to initiate Tesla connection');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
        <h1 className="text-2xl font-bold text-center mb-6">Connect Tesla Account</h1>
        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg text-sm">
            {error}
          </div>
        )}
        <button
          onClick={handleConnectTesla}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
        >
          Connect Tesla Account
        </button>
      </div>
    </div>
  );
};

export default ConnectCarPage;