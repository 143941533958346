import React, { useRef, useCallback } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/config';

// Components
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import GlobalStyle from './GlobalStyle';
import Mission from './components/Mission';
import LearnAndSave from './components/LearnAndSave';
import FAQ from './components/FAQ';
import SubscribeForm from './components/SubscribeForm';
import RotatingContent from './components/RotatingContent';
import ImproveSection from './components/ImproveSection';
import Safu from './components/Safu';

// Auth Components
import AuthPage from './components/Auth/AuthPage';
import ConnectCarPage from './components/Auth/ConnectCarPage';
import TeslaCallback from './components/Auth/TeslaCallback';
import ChooseVehiclePage from './components/Auth/ChooseVehiclePage';
import TelemetrySetupPage from './components/Auth/TelemetrySetupPage';
import TelemetryStatusPage from './components/Auth/TelemetryStatusPage';

// Context
import { TelemetryProvider } from './components/Auth/TelemetryContext';
import ProtectedRoute from './components/Auth/ProtectedRoute';

const HomePage: React.FC = () => {
  const subscribeFormRef = useRef<HTMLDivElement>(null);
  const scrollToSubscribeForm = useCallback(() => {
    subscribeFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <>
      <Hero />
      <Mission />
      <RotatingContent scrollToSubscribeForm={scrollToSubscribeForm} />
      <LearnAndSave scrollToSubscribeForm={scrollToSubscribeForm} />
      <Safu />
      <ImproveSection />
      <FAQ />
      <SubscribeForm ref={subscribeFormRef} />
    </>
  );
};

const Layout: React.FC<{
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}> = ({ children, showHeader = true, showFooter = true }) => {
  return (
    <>
      <GlobalStyle />
      {showHeader && <Header />}
      {children}
      {showFooter && <Footer />}
    </>
  );
};

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Layout showHeader={false} showFooter={false}>{children}</Layout>;
};

const App: React.FC = () => {
  const [authChecked, setAuthChecked] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <TelemetryProvider>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />

          <Route
            path="/auth"
            element={
              isAuthenticated ? (
                <Navigate to="/connect-car" replace />
              ) : (
                <AuthLayout>
                  <AuthPage />
                </AuthLayout>
              )
            }
          />

          {/* Protected Routes */}
          <Route
            path="/connect-car"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AuthLayout>
                  <ConnectCarPage />
                </AuthLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/auth/callback"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AuthLayout>
                  <TeslaCallback />
                </AuthLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/choose-vehicle"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AuthLayout>
                  <ChooseVehiclePage />
                </AuthLayout>
              </ProtectedRoute>
            }
          />

          {/* Telemetry Routes */}
          <Route
            path="/telemetry/setup"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AuthLayout>
                  <TelemetrySetupPage />
                </AuthLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/telemetry/status"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AuthLayout>
                  <TelemetryStatusPage />
                </AuthLayout>
              </ProtectedRoute>
            }
          />

          {/* Dashboard Route */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute 
                isAuthenticated={isAuthenticated}
                requiresTelemetry={true}
              >
                <AuthLayout>
                  <TelemetryStatusPage />
                </AuthLayout>
              </ProtectedRoute>
            }
          />

          {/* Catch-all Route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </TelemetryProvider>
    </BrowserRouter>
  );
};

export default App;