import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chrome, Loader2 } from 'lucide-react';
import { signInWithGoogle, auth } from '../../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';

const AuthPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/connect-car');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError('');
      await signInWithGoogle();
      // Navigation will be handled by the onAuthStateChanged listener
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      setError(error.message || 'Failed to sign in with Google. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-blue-700 to-white p-8">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-md">
        <h1 className="text-2xl text-gray-800 mb-6 text-center font-semibold">
          Welcome
        </h1>
        <p className="text-gray-600 text-center mb-8 text-sm">
          Choose your preferred way to continue
        </p>
        
        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg text-sm">
            {error}
          </div>
        )}

        <button
          onClick={handleGoogleSignIn}
          disabled={loading}
          className="w-full py-3 px-4 mb-3 border border-gray-200 rounded-full bg-white hover:bg-gray-50 
                   flex items-center justify-center gap-3 transition-all disabled:opacity-50 
                   disabled:cursor-not-allowed text-gray-700 font-medium"
        >
          {loading ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            <Chrome className="w-5 h-5 text-[#DB4437]" />
          )}
          Continue with Google
        </button>
      </div>
    </div>
  );
};

export default AuthPage;